import {SvgIcon} from '@mui/material';
import {SvgIconProps} from '@mui/material/SvgIcon';

type Props = SvgIconProps;

/**
 * Ikona manažer
 */
export const DownloadIcon = (props: Props) => (
    <SvgIcon {...props}>
        <path
            d="M35.7070312,23.7070312l-0.0002441-0.0002441
		c0.0001221-0.0001221,0.0003052-0.0001831,0.0004272-0.0003052c0.3903809-0.3903809,0.3903809-1.0233154,0-1.4136963
		s-1.0233154-0.3903809-1.4136963,0c-0.0001221,0.0001221-0.0001831,0.0003052-0.0003052,0.0004272l-0.0002441-0.0002441
		L25,31.5859375V10c0-0.5527344-0.4472656-1-1-1s-1,0.4472656-1,1v21.5859375l-9.2929688-9.2929688l-1.4140625,1.4140625
		L24,35.4140625L35.7070312,23.7070312z"
            transform="matrix(0.666667, 0, 0, 0.666667, -4.264391, -4.386638)"
        />
        <rect x="0.615" y="21.804" width="22.907" height="0.974" />
        <rect x="12" y="37" width="24" height="2" />
    </SvgIcon>
);
