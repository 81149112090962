import {gql} from '@apollo/client';

export const StepOneQueries = {
    gql: {
        stepOneSendData: gql`
            query StepOneQuery($input: PPPSopDataInput!) {
                prvniParalelniPripojeni {
                    formData(input: $input) {
                        aktivni
                        cisloSop
                        ean
                        obec
                        parseId
                        prilohy {
                            extId
                            filename
                            mimetype
                            size
                            typ
                        }
                        sopData {
                            adresy {
                                cisloPopisne
                                druh
                                cisloEvidencni
                                parcelniId
                                ulice
                                mistniCast
                                obec
                                cisloOrientacni
                                psc
                                stat
                                statKod
                                katastralniUzemiKod
                                katastralniUzemiNazev
                                parcelniCislo
                                parcelniCisloPoddodeleni
                            }
                            nazevFirmy
                            zadatelJmeno
                            zadatelPrijmeni
                            zadatelTyp
                            akumulace
                            akumulaceVykon
                            druhVyrobny
                            druhVyrobnyPopis
                            instalovanyVykonSop
                            rezervovanyVykonSop
                            typZdroje
                            umisteniVyrobny
                            zadatelDatumNarozeni
                            zadatelIc
                            typStridaceAku
                        }
                    }
                }
            }
        `,
    },
};
